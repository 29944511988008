header {
  background-color: #ffe3cf;
  font-family: "Bubblegum Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.logo {
  width: 90px;
  height: auto;
  display: block;
  text-indent: -9999px;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}
/* Add this to your CSS file or in a <style> tag */
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
}

.shake {
  animation: shake 0.3s;
  border-color: #ff0000;
}

.border-red-500 {
  border-color: #ff0000;
}

.react-calendar {
  width: 100%;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}

.react-calendar__tile {
  padding: 8px;
  margin: 2px;
  border-radius: 4px;
}

.react-calendar__tile--has-event {
  background: #ff6384;
  color: white;
}

.react-calendar__tile--now {
  background: #36a2eb;
  color: white;
}

.summary-card,
.chart-container,
.upcoming-events-container {
  min-height: 200px; /* Adjust as necessary */
}

.summary-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chart-container {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upcoming-events-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Sidebar visibility */
@media (max-width: 1024px) {
  .lg\\:hidden {
    display: none;
  }

  .lg\\:block {
    display: block;
  }

  .lg\\:w-20 {
    width: 20px;
  }

  .lg\\:w-64 {
    width: 64px;
  }
}

/* Add this CSS to your global stylesheet */

/* Placeholder styling */
.img-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #888;
  font-size: 1.5rem;
  height: 100%;
  width: 100%;
}

/* Ensure images fit well in their containers */
.img-gallery {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn {
  @apply bg-blue-500 text-white py-2 px-4 rounded-lg transition-all duration-300;
}

.btn:hover {
  @apply bg-blue-600;
}

.btn:active {
  @apply bg-blue-700;
}
